<template>
  <div>
    <header-top-dashboard neutral />
    <v-container
      fluid
      class="pt-0 px-6 mt-n16 container-general" 
    >
      <v-card
        v-if="currentNew"
        class="v-card-blue v-card v-sheet theme--light"
      >
        <v-card-title style="justify-content: space-between;">
          <h1>{{ $t('Versión') }} {{ currentNew.version }}</h1>
          <h3>{{ currentNew.date | dateYearEnd }}</h3>
        </v-card-title>
        <v-card-text
          v-html="currentNew.content"
        />
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
  import NewsService from '@/services/news.service.js'
  export default {
    name: 'ReleasesPage',
    components: {
      HeaderTopDashboard,
    },
    data () {
      return {
        currentNew: null,
      }
    },
    created () {
      const newId = this.$route.query.id
      NewsService.getNew({ id: newId })
        .then(
          (response) => {
            this.currentNew = response.new
          },
        )
    },
    methods: {
      formattDate () {

      },
    },
  }
</script>
